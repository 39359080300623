import { createSlice, PayloadAction } from '@reduxjs/toolkit';

const initialState: initialStateType = {
  customerInfo: {
    botExist: false,
    raffleExist: false,
    usr_phone_number: '',
    botId: 0,
    limited: false,
    usr_tg: '',
  },
  modal: {
    open: false,
  },
};

export const slice = createSlice({
  name: 'info',
  initialState: initialState,
  reducers: {
    setInfo(state, action: PayloadAction<initialInfoStateType>) {
      return {
        ...state,
        customerInfo: { ...action.payload },
      };
    },
    setModal(state, action: PayloadAction<{ open: boolean }>) {
      state.modal.open = action.payload.open;
    },
  },
});

export const { setInfo, setModal } = slice.actions;

export type initialInfoStateType = {
  usr_tg: string;
  usr_phone_number: string;
  botExist: boolean;
  botId: number;
  raffleExist: boolean;
  limited: boolean;
};
type initialStateType = {
  customerInfo: initialInfoStateType;
  modal: {
    open: boolean;
  };
};
