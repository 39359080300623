import React, { useEffect, useState } from 'react';
import { UsersTable } from './userTable/UsersTable';
import { useAppDispatch, useAppSelector } from '../../../common/hooks/storeHooks';
import { AdminFindUser } from './adminFindUser/AdminFindUser';
import { BlockTitle } from '../../sales/common/components/blockTitle/BlockTitle';
import s from './AdminUsers.module.scss';
import { adminUsersThunks } from '../adminUsersSlice';
import { SalesPagination } from '../../sales/common/components/pagination/Pagination';
import { getUsersPagesCountSelector } from '../adminSelectors';
import { isAppLoadingSelector } from '../../../bll/reducers/appSelectors';
import { PreLoader } from '../../sales/common/components/preLoader/PreLoader';
import { SortVariantType } from '../commonAdminTypes';
import { useCustomSearchParams } from '../../../common/hooks/useCustomSearchParams';

type AdminUsersParamsType = 'page' | 'size' | 'sortBy' | 'sortDirection';

export const AdminUsers = () => {
  const pagesCount = useAppSelector(getUsersPagesCountSelector);
  const isAppLoading = useAppSelector(isAppLoadingSelector);
  const dispatch = useAppDispatch();

  const [searchParams, setSearchParams] = useCustomSearchParams<AdminUsersParamsType>();
  const [sortVariant, setSortVariant] = useState<SortVariantType>('desc');

  const currentPage = searchParams.page || '1';
  const pageSize = searchParams.size || '10';
  const sortBy = searchParams.sortBy || 'id';
  const sortDirection = searchParams.sortDirection || sortVariant;

  useEffect(() => {
    dispatch(adminUsersThunks.getUsers({ page: currentPage, size: pageSize, sortBy, sortDirection }));
  }, [currentPage, sortDirection]);

  const onChangePage = (page: string) => {
    setSearchParams({ ...searchParams, page });
  };
  const sortChangeHandler = () => {
    const newSortVariant = sortVariant === 'desc' ? 'asc' : 'desc';
    setSortVariant(newSortVariant);
    setSearchParams({ ...searchParams, sortDirection: newSortVariant, page: '1' });
  };

  return (
    <>
      <div className={s.tableHeader}>
        <div className={s.tableTitleWrapper}>
          <BlockTitle title={'Пользователи'} />
        </div>
        <div className={s.searchWrapper}>
          <AdminFindUser disabled={isAppLoading} />
        </div>
      </div>
      {isAppLoading ? (
        <PreLoader />
      ) : (
        <>
          <UsersTable sortVariant={sortVariant} sortChangeHandler={sortChangeHandler} />
          <SalesPagination page={currentPage} pagesCount={pagesCount} onChange={onChangePage} />
        </>
      )}
    </>
  );
};
