import React from 'react';
import { SalesButtonGroup } from './SalesButtonGroup';
import { InOutButton } from '../../../../../common/components/inOutButton/InOutButton';
import { salesAuthThunks } from '../../../salesAuthSlice';
import { useAppDispatch } from '../../../../../common/hooks/storeHooks';
import { HeaderBurger } from './HeaderBurger';
import { useWindowDimensions } from '../../../../../common/utils/useWindowDimensions';
import { PATH } from '../../../../../common/path/puth';
import { useNavigate } from 'react-router-dom';

export const HeaderMenu = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { width } = useWindowDimensions();
  const SHOW_BURGER_WIDTH = 500;
  const isBurgerShow = width <= SHOW_BURGER_WIDTH;
  const handleLogout = () => dispatch(salesAuthThunks.logout());

  const menuItems = [
    { buttonTitle: 'Баланс', buttonAction: () => navigate(PATH.salesBalance) },
    { buttonTitle: 'База знаний', buttonAction: () => navigate(PATH.salesKnowledgeBase) },
    { buttonTitle: 'Настройки', buttonAction: () => navigate(PATH.salesSettings) },
  ];

  return (
    <div style={{ display: 'flex', gap: '40px' }}>
      {isBurgerShow ? (
        <HeaderBurger handleLogout={handleLogout} menuItems={menuItems} />
      ) : (
        <>
          <SalesButtonGroup />
          <InOutButton callback={handleLogout} btnTitle={'Выход'} />
        </>
      )}
    </div>
  );
};
