import React, { useEffect } from 'react';
import { useAppDispatch, useAppSelector } from '../../../../common/hooks/storeHooks';
import { useParams } from 'react-router-dom';
import { serverDateFormat } from '../../../sales/common/utils/serverDateFormat';
import { TeamTableRow } from '../../../sales/components/salesHome/team/teamTable/TeamTableRow';
import { BlockTitle } from '../../../sales/common/components/blockTitle/BlockTitle';
import { PreLoader } from '../../../sales/common/components/preLoader/PreLoader';
import { TeamTableLayout } from '../../../sales/components/salesHome/team/teamTable/TeamTableLayout';
import { SalesPagination } from '../../../sales/common/components/pagination/Pagination';
import { getManagerTeamSelector, managerTeamPagesCountSelector } from '../../adminManagerSelectors';
import { adminManagerThunks } from '../../adminManagerSlice';
import { isAppLoadingSelector } from '../../../../bll/reducers/appSelectors';
import { useCustomSearchParams } from '../../../../common/hooks/useCustomSearchParams';

type AdminManagerAllTeamParams = 'page' | 'size';
export const AdminManagerAllTeam = () => {
  const dispatch = useAppDispatch();
  const pagesCount = useAppSelector(managerTeamPagesCountSelector);
  const team = useAppSelector(getManagerTeamSelector);
  const { managerId } = useParams();
  const isFetching = useAppSelector(isAppLoadingSelector);

  const [searchParams, setSearchParams] = useCustomSearchParams<AdminManagerAllTeamParams>();

  const currentPage = searchParams.page || '1';
  const pageSize = searchParams.size || '10';

  useEffect(() => {
    if (managerId) {
      dispatch(adminManagerThunks.getManagerTeam({ id: managerId, page: currentPage, size: pageSize }));
    }
  }, [currentPage, managerId]);

  const onChangePage = (page: string) => {
    setSearchParams({ ...searchParams, page });
  };

  const teamTableRows = team.map(({ id, profit, createdAt, email, clientsCount, tg_userName }) => {
    const regDate = serverDateFormat(createdAt);
    return (
      <TeamTableRow
        key={id}
        clients={clientsCount}
        email={email}
        telegram={tg_userName}
        income={profit}
        regDate={regDate}
      />
    );
  });
  return (
    <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
      <BlockTitle title={'Команда'} />
      {isFetching ? (
        <PreLoader />
      ) : (
        <>
          <TeamTableLayout>{teamTableRows}</TeamTableLayout>
          <SalesPagination page={currentPage} onChange={onChangePage} pagesCount={pagesCount} disabled={isFetching} />
        </>
      )}
    </div>
  );
};
