import React, { useEffect } from 'react';
import { useAppDispatch, useAppSelector } from '../../../../common/hooks/storeHooks';
import { useParams } from 'react-router-dom';
import { serverDateFormat } from '../../../sales/common/utils/serverDateFormat';
import { ClientsTableRow } from '../../../sales/components/salesHome/clients/clinetsTable/ClientsTableRow';
import { BlockTitle } from '../../../sales/common/components/blockTitle/BlockTitle';
import { PreLoader } from '../../../sales/common/components/preLoader/PreLoader';
import { ClientsTableLayout } from '../../../sales/components/salesHome/clients/clinetsTable/ClientsTableLayout';
import { SalesPagination } from '../../../sales/common/components/pagination/Pagination';
import { getManagerClientsSelector, managerClientsPagesCountSelector } from '../../adminManagerSelectors';
import { adminManagerThunks } from '../../adminManagerSlice';
import { isAppLoadingSelector } from '../../../../bll/reducers/appSelectors';
import { useCustomSearchParams } from '../../../../common/hooks/useCustomSearchParams';

type AdminManagerAllClientsParams = 'page' | 'size';

export const AdminManagerAllClients = () => {
  const dispatch = useAppDispatch();
  const pagesCount = useAppSelector(managerClientsPagesCountSelector);
  const clients = useAppSelector(getManagerClientsSelector);
  const { managerId } = useParams();

  const isFetching = useAppSelector(isAppLoadingSelector);

  const [searchParams, setSearchParams] = useCustomSearchParams<AdminManagerAllClientsParams>();

  const currentPage = searchParams.page || '1';
  const pageSize = searchParams.size || '10';

  useEffect(() => {
    if (managerId) {
      dispatch(adminManagerThunks.getManagerClients({ id: managerId, page: currentPage, size: pageSize }));
    }
  }, [currentPage, managerId]);

  const onChangePage = (page: string) => {
    setSearchParams({ ...searchParams, page });
  };

  const clientsTableRows = clients.map(
    ({ id, count_subscribes, email_address, usr_tg, rate_plan, creation_timestamp }) => {
      const regDate = serverDateFormat(creation_timestamp);
      return (
        <ClientsTableRow
          key={id}
          subscribers={count_subscribes}
          email={email_address}
          telegram={usr_tg ?? 'Telegram не указан'}
          ratePlan={rate_plan}
          regDate={regDate}
        />
      );
    }
  );

  return (
    <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
      <BlockTitle title={'Клиенты'} />
      {isFetching ? (
        <PreLoader />
      ) : (
        <>
          <ClientsTableLayout>{clientsTableRows}</ClientsTableLayout>
          <SalesPagination page={currentPage} onChange={onChangePage} pagesCount={pagesCount} disabled={isFetching} />
        </>
      )}
    </div>
  );
};
