import React, { useEffect } from 'react';
import { finishDraws } from '../../../bll/reducers/mainFinishReducer';
import { useAppDispatch, useAppSelector } from '../../../common/hooks/storeHooks';
import { Picture } from '../../../common/utils/picture';
import { winnersSelector } from '../drawResultsSelector';
import { Winner } from './winner/Winner';
import style from './Winners.module.css';
import { getRaffleByIdCommon } from '../../customer/drawSliceCommon';

type WinnersProps = {
  winnerId: string | undefined;
};

export const Winners = ({ winnerId }: WinnersProps) => {
  const dispatch = useAppDispatch();
  const winners = useAppSelector(winnersSelector);
  const drawCommon = useAppSelector(state => state.drawCommon);

  useEffect(() => {
    dispatch(finishDraws());
    if (winnerId) {
      dispatch(getRaffleByIdCommon({ id: +winnerId }));
    }
  }, [winners.length, dispatch]);

  const variantsContent = () => {
    if (winners.length > 1) {
      return 'Победители';
    } else if (winners.length === 1) {
      return 'Победитель';
    }
    return <span className={style.noWinnersText}>Победители пока что не выбраны</span>;
  };
  return (
    <div className={style.winnersContainer}>
      <h2 className={style.winnersTitle}>Результаты розыгрыша</h2>
      <div className={style.container}>
        <div className={style.imageBlock}>
          <Picture
            srcBig={drawCommon.fileName || ''}
            className={style.image}
            srcAverage={drawCommon.fileName || ''}
            srcSmall={drawCommon.fileName || ''}
          />
          <p>{drawCommon.header}</p>
        </div>
        <div className={style.wrapper}>
          <h4 className={style.h4}>{variantsContent()}</h4>
          <ul className={style.winners}>
            {winners.map(({ prize, place, telegramName }, i) => {
              return <Winner key={i} place={place} winner={telegramName} prize={prize} />;
            })}
          </ul>
        </div>
      </div>
    </div>
  );
};
