import { ComponentPropsWithoutRef, CSSProperties, ElementType, ReactNode } from 'react';
import s from './Typography.module.scss';
import { clsx } from 'clsx';

type TypographyProps<T extends ElementType> = {
  children: ReactNode;
  variant: Component;
  component?: T;
  className?: string;
  mb?: number | string;
  mt?: number | string;
  mr?: number | string;
  ml?: number | string;
  mx?: number | string;
  my?: number | string;
  align?: CSSProperties['textAlign'];
  dark?: boolean;
  bold?: boolean;
  uppercase?: boolean;
  nonAdapt?: boolean;
} & ComponentPropsWithoutRef<T>;

export const Typography = <T extends ElementType>(props: TypographyProps<T>) => {
  const {
    variant,
    component,
    className,
    children,
    style,
    mr,
    ml,
    mt,
    mb,
    mx,
    my,
    align,
    dark = false,
    bold = false,
    uppercase = false,
    nonAdapt = false,
    ...rest
  } = props;
  const Component = component || COMPONENTS[variant] || 'span';

  const styles = {
    ...(mr && { marginRight: mr }),
    ...(ml && { marginLeft: ml }),
    ...(mt && { marginTop: mt }),
    ...(mb && { marginBottom: mb }),
    ...(mx && { marginRight: mx, marginLeft: mx }),
    ...(my && { marginTop: my, marginBottom: my }),
    ...(align && { textAlign: align }),
    ...(uppercase && { textTransform: 'uppercase' }),
    ...style,
  };

  return (
    <Component
      className={clsx(s[variant], className, s.typography, dark && s.dark, bold && s.bold, nonAdapt && s.nonAdapt)}
      style={styles}
      {...rest}
    >
      {children}
    </Component>
  );
};
const COMPONENTS = {
  title_block: 'h1',
  title_block_768: 'h2',
  title_primary_1: 'h2',
  title_primary_2: 'h2',
  title_secondary: 'h3',
  header_primary: 'h3',
  header_secondary: 'p',
  subheader_primary: 'p',
  subheader_secondary: 'p',
  typeface_main: 'p',
  btn_s: 'span',
  btn_m: 'span',
  btn_l: 'span',
  title_any_cads: 'p',
  big_link: 'a',
  statistic: 'p',
  typeface_bold: 'p',
  small_text: 'p',
  small_link: 'a',
  title_side_menu: 'p',
  footer_s: 'span',
  footer_m: 'span',
  footer_xl: 'p',
  footer_l: 'p',
  footer_link: 'a',
  footer_link_xl: 'a',
  very_small_text: 'span',
} as const;

export const Green = ({ children }: { children: ReactNode }) => {
  return <span className={s.green}>{children}</span>;
};

export type Component = keyof typeof COMPONENTS;
