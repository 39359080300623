import React, { FC } from 'react';
import logo from '../../../../../assets/svg/logo.svg';
import logoWhite from '../../../../../assets/svg/logo-white.svg';
import mobileLogo from '../../../../../assets/svg/logo-mobile.svg';
import { useNavigate } from 'react-router-dom';
import { useWindowDimensions } from '../../../../../common/utils/useWindowDimensions';

type PropsType = {
  navigateLink: string;
  toggleLogoWidth: number;
  isDarkTheme?: boolean;
};
export const MainLogo: FC<PropsType> = ({ navigateLink, toggleLogoWidth, isDarkTheme }) => {
  const CHANGE_LOGO_WIDTH = toggleLogoWidth;
  const { width } = useWindowDimensions();
  const isDisplayMobileLogo = width <= CHANGE_LOGO_WIDTH;
  const navigate = useNavigate();
  const onClickHandler = () => navigate(navigateLink);

  const currentLogo = isDarkTheme ? logoWhite : logo;
  const currentMobileLogo = isDarkTheme ? mobileLogo : mobileLogo;

  return (
    <img
      style={{ cursor: 'pointer' }}
      src={isDisplayMobileLogo ? currentMobileLogo : currentLogo}
      alt={'Telegiv_logo'}
      onClick={onClickHandler}
    />
  );
};
