import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { ActiveRaffleStats, apiCustomer, initialRaffleType, RaffleType } from './API/customersAPI';
import { setAppStatusAC } from '../../bll/reducers/appReducer';
import { isAxiosError } from 'axios';
import { handleServerNetworkError } from '../../common/utils/error-utils';
import { setInfo } from '../../bll/reducers/infoReducer';
import { thunkErrorHandler } from '../../common/utils/thunkErrorHandler';
import { raffles } from './components/profile/utils/raffles';

const initialState: initialRaffleType = {
  raffles: {
    active: {
      fileName: null,
      id: null,
      customer_id: null,
      bot_id: null,
      header: null,
      description: null,
      prizes: null,
      sponsors: null,
      begin_timestamp: null,
      end_timestamp: null,
      countries: null,
    },
    planned: {
      id: null,
      customer_id: null,
      bot_id: null,
      begin_timestamp: null,
      description: null,
      end_timestamp: null,
      header: null,
      prizes: null,
      countries: null,
      sponsors: null,
      fileName: null,
    },
    completed: [],
  },
  profileLoaded: false,
  statistic: {
    customer_id: 0,
    all_sub: '',
    male: '',
    female: '',
    age_18: '',
    age_18_24: '',
    age_25_34: '',
    age_35_44: '',
    age_45: '',
    belarus: '',
    rus: '',
    ukr: '',
    kz: '',
    uzb: '',
    other_country: '',
    invite_sub: '',
    last_sub_by_30_days: '',
    last_sub_by_1_hour: '',
    activeRaffleStats: {
      customer_id: 0,
      raffle_id: 0,
      begin_timestamp: null,
      end_timestamp: null,
      num_tickets: 0,
      num_participants: 0,
    },
  },
  raffleRecalculate: false,
  test: [],
};

export const getActiveRaffleStats = createAsyncThunk<
  {
    activeRaffleStats: ActiveRaffleStats;
  },
  void
>('raffle/getActiveRaffleStats', async (_, { dispatch, rejectWithValue }) => {
  try {
    const response = await apiCustomer.getActiveRaffleStats();
    return { activeRaffleStats: response.data };
  } catch (e) {
    thunkErrorHandler(e, dispatch);
    return rejectWithValue(null);
  }
});

export const setRaffle = createAsyncThunk('raffle/setRaffle', async (_, thunkAPI) => {
  const result = await apiCustomer.getRaffle();

  const { completed, active, planned } = raffles(result.data);
  thunkAPI.dispatch(test2(result.data));

  return { completed, active, planned };
});
export const deleteRaffle = createAsyncThunk('raffle/deleteRaffle', async (id: number | null, thunkAPI) => {
  try {
    thunkAPI.dispatch(isLoaded({ loaded: true }));
    thunkAPI.dispatch(setAppStatusAC({ status: 'loading' }));
    await apiCustomer.deleteRaffle(id);
    thunkAPI.dispatch(setAppStatusAC({ status: 'succeeded' }));
    if (id) {
      return id;
    } else {
      return 'delete';
    }
  } catch (err) {
    if (isAxiosError(err)) {
      handleServerNetworkError(err.message, thunkAPI.dispatch);
      return thunkAPI.rejectWithValue({ message: 'Не удалось удалить розыгрыш.' });
    }
    handleServerNetworkError('Не удалось удалить розыгрыш.', thunkAPI.dispatch);
    thunkAPI.dispatch(isLoaded({ loaded: false }));
    return thunkAPI.rejectWithValue({ message: 'Не удалось удалить розыгрыш.' });
  }
});
export const setStatistic = createAsyncThunk('raffle/setStatistic', async (_, thunkAPI) => {
  const statistic = apiCustomer.getStatistic();
  const info = apiCustomer.customerInfo2();
  const result = await Promise.allSettled([statistic, info]);
  // @ts-ignore
  thunkAPI.dispatch(setInfo(result[1].value.data));
  // @ts-ignore
  return result[0].value.data;
});

export const slice = createSlice({
  name: 'raffle',
  initialState,
  reducers: {
    isLoaded: (
      state,
      action: PayloadAction<{
        loaded: boolean;
      }>
    ) => {
      state.profileLoaded = action.payload.loaded;
    },
    setRaffleRecalculate: (
      state,
      action: PayloadAction<{
        raffleRecalculate: boolean;
      }>
    ) => {
      state.raffleRecalculate = action.payload.raffleRecalculate;
    },
    test1: (state, action: PayloadAction<any>) => {
      state = {
        raffles: { ...state.raffles, ...action.payload },
        test: state.test.map(el => ({ ...el })),
        statistic: { ...state.statistic },
        raffleRecalculate: state.raffleRecalculate,
        profileLoaded: state.profileLoaded,
      };
      return state;
    },
    test2: (state, action: PayloadAction<RaffleType>) => {
      state = {
        raffles: { ...state.raffles },
        test: action.payload.map(el => ({ ...el })),
        statistic: { ...state.statistic },
        raffleRecalculate: state.raffleRecalculate,
        profileLoaded: state.profileLoaded,
      };
      return state;
    },
  },
  extraReducers: builder => {
    // builder.addCase(setRaffle.fulfilled, (state, { payload }) => {
    //
    //   return state = {
    //     raffles: payload,
    //     profileLoaded: false,
    //     statistic: { ...state.statistic },
    //     raffleRecalculate: true,
    //   };
    // });

    builder.addCase(setRaffle.fulfilled, (state, { payload }) => {
      return {
        raffles: {
          active: payload.active,
          planned: payload.planned,
          completed: payload.completed.slice(-4, payload.completed.length),
        },
        profileLoaded: false,
        statistic: { ...state.statistic },
        raffleRecalculate: true,
        test: state.test.map(el => ({ ...el })),
      };
    });

    builder.addCase(deleteRaffle.fulfilled, state => {
      if (state.raffles.active) {
        return (state = {
          raffles: { ...state.raffles, active: undefined },
          profileLoaded: false,
          statistic: { ...state.statistic },
          raffleRecalculate: true,
          test: state.test.map(el => ({ ...el })),
        });
      }
      if (state.raffles.planned) {
        state.raffles = { ...state.raffles, planned: undefined };
      }
    });

    builder
      .addCase(setStatistic.fulfilled, (state, { payload }) => {
        const active = state.raffles.active ? state.raffles.active : undefined;
        const planned = state.raffles.planned ? state.raffles.planned : undefined;

        state = {
          raffles: {
            active,
            planned,
            completed: state.raffles.completed.map(el => ({ ...el })),
          },
          profileLoaded: false,
          statistic: { activeRaffleStats: state.statistic.activeRaffleStats, ...payload },
          raffleRecalculate: true,
          test: state.test.map(el => ({ ...el })),
        };
        return state;
      })
      .addCase(getActiveRaffleStats.fulfilled, (state, { payload }) => {
        state.statistic.activeRaffleStats = payload.activeRaffleStats;
      });
  },
});

export const { isLoaded, setRaffleRecalculate, test2, test1 } = slice.actions;
