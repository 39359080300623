import { FC, useState } from 'react';

export type PictureProps = {
  srcBig: string;
  srcAverage: string;
  srcSmall: string;
  className: string;
};

export const Picture: FC<PictureProps> = ({ srcBig, srcAverage, srcSmall, className }) => {
  // const [srcError, setSrc] = useState('');
  // Todo check set error this images
  return (
    <picture className={className}>
      <source srcSet={srcSmall} media='(min-width: 328px)' />
      <source srcSet={srcAverage} media='(min-width: 416px)' />
      <img
        src={srcBig}
        alt={'Raffles'}
        // onError={e => {
        //   e.currentTarget.onerror = null;
        //   e.currentTarget.src = 'default-to-broken-picture.jpg';
        //   setSrc('default-to-broken-picture.jpg');
        //   console.error('Error');
        // }}
      />
    </picture>
  );
};
