import React, { FC } from 'react';
import s from './UsersTable.module.scss';
import { CustomButton } from '../../../../common/components/customButton/CustomButton';
import { UsersTableCell } from './UsersTableCell';
import { PATH } from '../../../../common/path/puth';
import { useNavigate } from 'react-router-dom';
import { ratePlans } from '../../../../common/utils/ratePlanCount';
import { useWindowDimensions } from '../../../../common/utils/useWindowDimensions';

type PropsType = {
  id: number;
  email: string;
  ratePlan: string;
  ratePlanId: number;
  drawsCount: number;
  regDate: string;
};
export const UsersTableRow: FC<PropsType> = ({ id, email, ratePlan, drawsCount, regDate, ratePlanId }) => {
  const navigate = useNavigate();

  const { width } = useWindowDimensions();
  const ADD_ONCLICK_TO_ROW_WIDTH = 780;
  const isOnclickAdd = width <= ADD_ONCLICK_TO_ROW_WIDTH;
  const editUserHandler = () => {
    navigate(`${PATH.editUser}/${id}`);
  };
  const getSubscribersCellVariant = () => {
    return drawsCount > ratePlans[ratePlanId] ? 'subscribersWarning' : 'subscribers';
  };
  const subscribersCellVariant = getSubscribersCellVariant();

  return (
    <tr onClick={isOnclickAdd ? editUserHandler : undefined}>
      <td className={s.date}>
        <UsersTableCell text={regDate} />
      </td>
      <td className={s.id}>
        <UsersTableCell text={id} />
      </td>
      <td className={s.email}>
        <UsersTableCell text={email} />
      </td>
      <td className={s.subscription}>
        <UsersTableCell text={`${ratePlan}к`} variant={'subscription'} />
      </td>
      <td className={s.subscribers}>
        <UsersTableCell text={drawsCount} variant={subscribersCellVariant} />
      </td>
      <td className={s.edit}>
        <div>
          <CustomButton
            title={'Редактировать'}
            width={'150'}
            textColor={'white'}
            color={'secondary'}
            callback={editUserHandler}
          />
        </div>
      </td>
    </tr>
  );
};
