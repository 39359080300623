import { Data } from './types';

export const rows: Data[] = [
  { id: 1, ignored: 11, blocked: 4, returned: 6, reached: 1 },
  { id: 2, ignored: 108, blocked: 22, returned: 56, reached: 14 },
  { id: 3, ignored: 187, blocked: 102, returned: 37, reached: 11 },
  { id: 4, ignored: 122, blocked: 44, returned: 23, reached: 67 },
  { id: 5, ignored: 18, blocked: 2, returned: 1, reached: 5 },
  { id: 6, ignored: 8, blocked: 6, returned: 2, reached: 2 },
  { id: 7, ignored: 76, blocked: 29, returned: 12, reached: 19 },
  { id: 8, ignored: 101, blocked: 49, returned: 41, reached: 31 },
  { id: 9, ignored: 122, blocked: 44, returned: 23, reached: 67 },
  { id: 10, ignored: 18, blocked: 2, returned: 1, reached: 5 },
  { id: 11, ignored: 8, blocked: 6, returned: 2, reached: 2 },
  { id: 12, ignored: 76, blocked: 29, returned: 12, reached: 19 },
  { id: 13, ignored: 101, blocked: 49, returned: 41, reached: 31 },
  { id: 14, ignored: 122, blocked: 44, returned: 23, reached: 67 },
  { id: 15, ignored: 18, blocked: 2, returned: 1, reached: 5 },
  { id: 16, ignored: 8, blocked: 6, returned: 2, reached: 2 },
  { id: 17, ignored: 76, blocked: 29, returned: 12, reached: 19 },
  { id: 18, ignored: 154, blocked: 49, returned: 41, reached: 31 },
  { id: 19, ignored: 456, blocked: 139, returned: 81, reached: 39 },
  { id: 20, ignored: 234, blocked: 149, returned: 23, reached: 22 },
  { id: 21, ignored: 155, blocked: 69, returned: 49, reached: 11 },
];
