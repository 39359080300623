import React, { useEffect, useState } from 'react';
import s from './AdminTeam.module.scss';
import { ManagersTable } from './managersTable/ManagersTable';
import { useAppDispatch, useAppSelector } from '../../../common/hooks/storeHooks';
import { BlockTitle } from '../../sales/common/components/blockTitle/BlockTitle';
import { SalesPagination } from '../../sales/common/components/pagination/Pagination';
import { adminManagerThunks } from '../adminManagerSlice';
import { adminManagersDataSelector, adminManagersPagesCountSelector } from '../adminManagerSelectors';
import { isAppLoadingSelector } from '../../../bll/reducers/appSelectors';
import { PreLoader } from '../../sales/common/components/preLoader/PreLoader';
import { SortVariantType } from '../commonAdminTypes';
import { useCustomSearchParams } from '../../../common/hooks/useCustomSearchParams';
import { SORT_DIRECTION } from '../../../common/constants/enumConstants';

type AdminManagersParamsType = 'page' | 'size' | 'sortBy' | 'sortDirection';

export const AdminTeam = () => {
  const dispatch = useAppDispatch();
  const managersData = useAppSelector(adminManagersDataSelector);
  const pagesCount = useAppSelector(adminManagersPagesCountSelector);
  const isFetching = useAppSelector(isAppLoadingSelector);

  const [searchParams, setSearchParams] = useCustomSearchParams<AdminManagersParamsType>();

  const [sortVariant, setSortVariant] = useState<SortVariantType>('desc');

  const currentPage = searchParams.page || '1';
  const pageSize = searchParams.size || '10';
  const sortBy = searchParams.sortBy || 'id';
  const sortDirection = searchParams.sortDirection || sortVariant;

  useEffect(() => {
    dispatch(adminManagerThunks.getManagers({ page: currentPage, size: pageSize, sortBy, sortDirection }));
  }, [dispatch, currentPage, sortDirection]);

  const onChangePage = (page: string) => {
    setSearchParams({ ...searchParams, page });
  };

  const sortChangeHandler = () => {
    const newSortVariant = sortVariant === SORT_DIRECTION.Desc ? SORT_DIRECTION.Asc : SORT_DIRECTION.Desc;
    setSortVariant(newSortVariant);
    setSearchParams({ ...searchParams, sortDirection: newSortVariant, page: '1' });
  };

  return (
    <div>
      <div className={s.adminTeamBlock}>
        <BlockTitle title={'Менеджеры'} />
        {isFetching ? (
          <PreLoader />
        ) : (
          <>
            <ManagersTable
              managersData={managersData}
              sortVariant={sortVariant}
              sortChangeHandler={sortChangeHandler}
            />
            <SalesPagination page={currentPage} pagesCount={pagesCount} onChange={onChangePage} />
          </>
        )}
      </div>
    </div>
  );
};
