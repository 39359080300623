import React, { FC, ReactNode, useRef } from 'react';
import ReactDOM from 'react-dom';
import { useOnClickOutside } from 'usehooks-ts';
import s from './PortalModal.module.scss';

type Props = {
  children: ReactNode;
  closeModalOnOutsideClick: () => void;
};

export const PortalModal: FC<Props> = ({ children, closeModalOnOutsideClick }) => {
  const portalContainer = document.getElementById('modal-root');
  const ref = useRef(null);
  useOnClickOutside(ref, closeModalOnOutsideClick);

  return ReactDOM.createPortal(
    <div className={s.portalModalWrapper}>
      <div ref={ref}>{children}</div>
    </div>,
    portalContainer!
  );
};
