import React, { useEffect } from 'react';
import { AdminManagerInfoBlock } from './adminManagerInfoBlock/AdminManagerInfoBlock';
import { BlockTitle } from '../../sales/common/components/blockTitle/BlockTitle';
import { AdminManagerStatsBlock } from './adminManagerStatsBlock/AdminManagerStatsBlock';
import { AdminManagerClients } from './adminManagerClients/AdminManagerClients';
import { AdminManagerTeam } from './adminManagerTeam/AdminManagerTeam';
import { useParams } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from '../../../common/hooks/storeHooks';
import { adminManagerThunks } from '../adminManagerSlice';
import { managerEmailAddressSelector } from '../adminManagerSelectors';
import { isAppLoadingSelector } from '../../../bll/reducers/appSelectors';
import { PreLoader } from '../../sales/common/components/preLoader/PreLoader';
import { AdminManagerHistory } from './adminManagerHistory/AdminManagerHistory';

export const AdminManagerPage = () => {
  const { managerId } = useParams();
  const dispatch = useAppDispatch();
  const managerEmail = useAppSelector(managerEmailAddressSelector);
  const isFetching = useAppSelector(isAppLoadingSelector);

  useEffect(() => {
    if (managerId) {
      dispatch(adminManagerThunks.getManagerById({ id: managerId }));
      dispatch(adminManagerThunks.getManagerTeam({ id: managerId }));
      dispatch(adminManagerThunks.getManagerClients({ id: managerId }));
    }
  }, [managerId]);

  return isFetching ? (
    <PreLoader />
  ) : (
    <>
      <BlockTitle title={`Менеджер - ${managerEmail}`} />
      <AdminManagerInfoBlock />
      <AdminManagerStatsBlock />
      <AdminManagerClients />
      <AdminManagerTeam />
      <br />
      <br />
      <AdminManagerHistory />
    </>
  );
};
