import React, { FC } from 'react';
import CustomizedMenus from './menu/Menu';
import { NavLink, useNavigate } from 'react-router-dom';
import { PATH } from '../../path/puth';
import { useAppDispatch, useAppSelector } from '../../hooks/storeHooks';
import { customerAuthActions, customerLogOut } from '../../../features/customer/customerAuthSlice';

import circle from '../../../assets/svg/circle.svg';
import logo from '../../../assets/svg/logo.svg';
import star from '../../../assets/svg/star.svg';
import exit from '../../../assets/svg/exit.svg';
import style from './Header.module.css';

type HeaderPropsType = {
  settings?: boolean;
  login?: boolean;
  draw?: boolean;
  result?: boolean;
  title?: string;
  ratePlans?: boolean;
  logoOnly?: boolean;
};

const Header: FC<HeaderPropsType> = ({ settings, logoOnly, draw, result, title, ratePlans }) => {
  const botExist = useAppSelector(state => state.customerLogin.botExist);
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const onLogOut = () => {
    dispatch(customerLogOut());
    navigate(PATH.main);
  };
  const onProfile = () => {
    if (botExist) {
      navigate(`${PATH.customerBaseUrl}${PATH.profile}`);
    } else {
      dispatch(customerAuthActions.setReinitialized());
      navigate(PATH.main, { replace: true });
    }
  };

  const selectStyleHandler = (isActive: boolean): string => {
    return isActive ? style.underlineTrue : style.underlineFalse;
  };
  const toRatePlansPage = () => navigate(PATH.selectTariffPlan, { replace: true });
  const onSettingsBot = () => navigate(PATH.settingsBot, { replace: true });

  return (
    <div className={style.headerContainer}>
      <img className={style.logo} src={logo} alt={'logo'} onClick={onProfile} />
      <div className={style.items}>
        {draw && (
          <>
            {' '}
            <div className={style.balance}>
              <img className={style.circle} src={circle} alt={'circle'} />
              <div>Баланс 194$</div>
              <img className={style.star} src={star} alt={'start'} />
            </div>
            <div className={style.info}>
              <div>Боты</div>
              <div>Розыгрыши</div>
              <div>Результаты</div>
            </div>
          </>
        )}
        {!result && (
          <>
            <div className={style.shownMenu}>
              {' '}
              {ratePlans && (
                <div className={style.settings} onClick={toRatePlansPage}>
                  <NavLink to={PATH.selectTariffPlan} className={({ isActive }) => selectStyleHandler(isActive)} end>
                    Тарифы
                  </NavLink>
                </div>
              )}
              {settings && (
                <div className={style.settings} onClick={onSettingsBot}>
                  <NavLink to={PATH.settingsBot} className={({ isActive }) => selectStyleHandler(isActive)} end>
                    Настройки
                  </NavLink>
                </div>
              )}
              {!logoOnly && (
                <div className={style.exitContainer} onClick={onLogOut}>
                  <img src={exit} alt='' />
                  <span className={style.exit}>{title}</span>
                </div>
              )}
            </div>
            {!logoOnly && (
              <CustomizedMenus onLogOut={onLogOut} onSettingsBot={onSettingsBot} toRatePlansPage={toRatePlansPage} />
            )}
          </>
        )}
      </div>
    </div>
  );
};

export default Header;
