// generic function
// export const handleServerAppError = <T>(mockData: ResponseType<T>, dispatch: ErrorUtilsDispatchType) => {
//   if (mockData.messages.length) {
//     dispatch(setAppErrorAC({error: mockData.messages[0]}))
//   }
//   else {
//     dispatch(setAppErrorAC({error: 'Some error occurred'}))
//   }
//   dispatch(setAppStatusAC({status: 'failed'}))
// }

import { setAppErrorAC, SetAppErrorACType, setAppStatusAC, SetAppStatusACType } from '../../bll/reducers/appReducer';
import { Dispatch } from 'redux';

export const handleServerNetworkError = (message: string, dispatch: ErrorUtilsDispatchType) => {
  dispatch(setAppStatusAC({ status: 'failed' }));
  dispatch(setAppErrorAC({ error: message }));
};

export type ErrorUtilsDispatchType = Dispatch<SetAppErrorACType | SetAppStatusACType>;
