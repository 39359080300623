import { initialInfoStateType } from '../../../bll/reducers/infoReducer';
import { AdminAuthType, PostRegistrationCustomerType } from '../../../api/apiAdmin';
import { customerInstance } from './customerInstance';

export const apiCustomer = {
  postRegistrationCustomer(payload: AdminAuthType) {
    return customerInstance.post<PostRegistrationCustomerType>(`auth/customer/registration`, {
      email_address: payload.email_address,
      password: payload.password,
      promo: payload.promo,
    });
  },
  postLoginizationCustomer(payload: AdminAuthType) {
    return customerInstance.post<{ accessToken: string }>(`auth/customer/login`, {
      email_address: payload.email_address,
      password: payload.password,
    });
  },
  getMeCustomer() {
    return customerInstance.get<CustomerMeResponse>('auth/customer/me');
  },
  createBot(payload: { token: string; firstName: string; userName: string; customerId: number }) {
    return customerInstance.post<ResponseCreateBotType>('bot/create', payload);
  },
  createRaffle(payload: any) {
    return customerInstance.post<ResponseCreateRaffleType>('raffle', payload);
  },
  // updateUserContacts(payload: UpdateUserContactsType) {
  //   return customerInstance.post<boolean>("raffle/update/info", payload)
  // },
  customerLogOut(): Promise<any> {
    return customerInstance.post('auth/customer/logout');
  },
  customerInfo() {
    return customerInstance.get<infoCustomer>('/customers/info');
  },
  customerInfo2() {
    return customerInstance.get<initialInfoStateType>('/customers/info');
  },
  refreshToken() {
    return customerInstance.post<{ accessToken: string }>('auth/customer/refresh-token');
  },
  getRaffleById(id: number) {
    return customerInstance.get<RaffleTypeById>(`raffle/${id}`);
  },
  getRaffle() {
    return customerInstance.get<RaffleType>('raffle/my/raffles');
  },
  deleteRaffle(id: null | number) {
    return customerInstance.delete(`raffle/${id}`);
  },
  putRaffle(formData: any, id: number) {
    return customerInstance.put(`raffle/${id}`, formData);
  },
  getStatistic() {
    return customerInstance.get<staticType>('statistics/customer');
  },
  getActiveRaffleStats() {
    return customerInstance.get<ActiveRaffleStats>('statistics/active-raffle');
  },
  getBotId() {
    return customerInstance.get<number>('bot/botid');
  },
  getBotUsername() {
    return customerInstance.get<string>('customers/bottoken');
  },
  getBotLink(customerId: number | null) {
    return customerInstance.get<{ botLink: string }>(`bot/link/${customerId}`);
  },
  putBotToken(payload: { token: string; firstName: string; userName: string }) {
    return customerInstance.put<{ token: string }>('bot/token', payload);
  },
  // getUserTelegramInfo(token: string) {
  //   return customerInstance.get(`https://api.telegram.org/bot${token}/getMe`)
  // },
  startDrawBot() {
    return customerInstance.post('/bot/raffle/add');
  },
  resendEmailConfirmationMessage(params: { email: string }) {
    return customerInstance.post('/auth/customer/registration-email-resending', params);
  },
  registrationConfirmation(params: { confirmationCode: string }) {
    return customerInstance.post('/auth/customer/registration-confirmation', params);
  },
  passwordRecovery(params: { email: string }) {
    return customerInstance.post('/auth/customer/password-recovery', params);
  },
  checkRecoveryCode(params: { recoveryCode: string }) {
    return customerInstance.post('/auth/customer/check-recovery-code', params);
  },
  setNewPassword(params: { newPassword: string; recoveryCode: string }) {
    return customerInstance.post('/auth/customer/new-password', params);
  },
};

export type CustomerMeResponse = {
  id: number;
  email_address: string;
  usr_tg: string;
  usr_phone_number: string;
  email_confirmation: boolean;
  rate_plan_id: number;
  verified: boolean;
  creation_timestamp: string;
  draws_count: number;
};
export type ActiveRaffleStats = {
  customer_id: number;
  raffle_id: number;
  begin_timestamp: Date | null;
  end_timestamp: Date | null;
  num_tickets: number;
  num_participants: number;
};
export type ResponseCreateBotType = {
  customer_id: number | null;
  first_name: string | null;
  id: number | null;
  token: string | null;
  username: string | null;
  addAttempts: number;
};
export type ResponseCreateRaffleType = {
  customer_id: number;
  bot_id: number;
  id: number;
  prizes: string[];
  description: string;
  sponsors: string[];
  begin_timestamp: string;
  end_timestamp: string;
  header: string;
  countries: string[];
};
export type infoCustomer = {
  botExist: boolean;
  raffleExist: boolean;
  usr_phone_number: string;
  usr_tg: string;
};

export type RaffleType = {
  fileName: string | null;
  id: number | null;
  customer_id: number | null;
  bot_id: number | null;
  prizes: string[] | null;
  description: string | null;
  sponsors: string[] | null;
  begin_timestamp: Date | null;
  end_timestamp: Date | null;
  header: string | null;
  countries: string[] | null;
}[];

export type RaffleTypeById = {
  fileName: string | null;
  id: number | null;
  customer_id: number | null;
  bot_id: number | null;
  header: string | null;
  description: string | null;
  prizes: string[] | null;
  sponsors: string[] | null;
  begin_timestamp: Date | null;
  end_timestamp: Date | null;
  countries: string[] | null;
};

export type initialRaffleType = {
  raffles: {
    completed: RaffleTypeById[];
    active: RaffleTypeById | undefined;
    planned: RaffleTypeById | undefined;
  };
  profileLoaded: boolean;
  statistic: staticType;
  raffleRecalculate?: boolean;
  test: RaffleType;
};
export type staticType = {
  age_18: string;
  age_18_24: string;
  age_25_34: string;
  age_35_44: string;
  age_45: string;
  all_sub: string;
  belarus: string;
  customer_id: number;
  female: string;
  invite_sub: string;
  kz: string;
  last_sub_by_1_hour: string;
  last_sub_by_30_days: string;
  male: string;
  other_country: string;
  rus: string;
  ukr: string;
  uzb: string;
  activeRaffleStats: ActiveRaffleStats;
};
