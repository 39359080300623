import React, { FC } from 'react';
import s from './AdminDrawCardModal.module.scss';
import moment from 'moment';
import { CustomButton } from '../../../../common/components/customButton/CustomButton';

type Props = {
  originalHeader: string;
  description: string;
  fileName: string;
  endTimestamp: string;
  closeModal: () => void;
};

export const AdminDrawCardModal: FC<Props> = ({ originalHeader, description, fileName, endTimestamp, closeModal }) => {
  const endDrawDate = moment(endTimestamp).locale('ru').format(`D MMMM в HH:mm`);

  return (
    <div className={s.modalDrawCard}>
      <img className={s.modalDrawCardImage} src={fileName} alt='draw_cover' />
      <span className={s.modalDrawCardHeader}>{originalHeader}</span>
      <div className={s.modalDrawCardInfo}>
        <p className={s.modalDrawCardDescription}>{description}</p>
        <span className={s.modalDrawCardEndDate}>
          Розыгрыш состоится: <b>{endDrawDate}</b>
        </span>
        <p className={s.modalDrawCardText}>
          Наш робот выберет победителя с помощью рандома. Проверит подписку и сразу же отправит результаты розыгрыша
          всем участникам.
          <br />
        </p>
        <p className={s.modalDrawCardText}>
          <b>Нажми кнопку 'Я в деле' 👇</b>
        </p>
        <CustomButton title={'Закрыть'} textColor={'white'} callback={closeModal} font={'main'} />
      </div>
    </div>
  );
};
