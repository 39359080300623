import { createSlice, isAnyOf, PayloadAction } from '@reduxjs/toolkit';
import { salesFulfilled, salesPending, salesRejected } from '../../features/sales/salesAuthSlice';
import { adminUsersFulfilled, adminUsersPending, adminUsersRejected } from '../../features/admin/adminUsersSlice';
import {
  adminManagerFulfilled,
  adminManagerPending,
  adminManagerRejected,
} from '../../features/admin/adminManagerSlice';

export const slice = createSlice({
  name: 'app',

  initialState: {
    status: 'succeeded' as RequestStatusType,
    error: null as string | null,
    successMessage: null as string | null,
    isInitialized: false,
  },

  reducers: {
    setAppStatusAC(state, action: PayloadAction<{ status: RequestStatusType }>) {
      state.status = action.payload.status;
    },

    setAppErrorAC(state, action: PayloadAction<{ error: string | null }>) {
      state.error = action.payload.error;
    },
    setAppSuccessMessage(state, action: PayloadAction<{ successMessage: string | null }>) {
      state.successMessage = action.payload.successMessage;
    },
  },

  extraReducers: builder => {
    builder
      .addMatcher(isAnyOf(salesPending, adminUsersPending, adminManagerPending), state => {
        state.status = 'loading';
      })
      .addMatcher(isAnyOf(salesFulfilled, adminUsersFulfilled, adminManagerFulfilled), state => {
        state.status = 'succeeded';
      })
      .addMatcher(isAnyOf(salesRejected, adminUsersRejected, adminManagerRejected), state => {
        state.status = 'failed';
      });
  },
});

export const { setAppStatusAC, setAppErrorAC, setAppSuccessMessage } = slice.actions;

// для error-utils
export type SetAppStatusACType = ReturnType<typeof setAppStatusAC>;
export type SetAppErrorACType = ReturnType<typeof setAppErrorAC>;

export type RequestStatusType = 'idle' | 'loading' | 'succeeded' | 'failed';
