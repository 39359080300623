import React from 'react';
import { NavLink } from 'react-router-dom';
import s from './SalesButtonGroup.module.scss';
import { PATH } from '../../../../../common/path/puth';

export const SalesButtonGroup = () => {
  const getClassName = ({ isActive }: { isActive: boolean }) => (isActive ? s.activeHeaderLink : s.headerLink);

  return (
    <div className={s.buttonGroup}>
      <NavLink className={getClassName} to={PATH.salesBalance}>
        Баланс
      </NavLink>
      <NavLink className={getClassName} to={PATH.salesKnowledgeBase}>
        База знаний
      </NavLink>
      <NavLink className={getClassName} to={PATH.salesSettings}>
        Настройки
      </NavLink>
    </div>
  );
};
