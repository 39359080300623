export enum PATH {
  // CUSTOMER ROUTES PATH
  customer = 'customer/*',
  customerBaseUrl = '/customer/',
  rules = 'auth/registration/rules',
  registration = 'auth/registration',
  login = 'auth/login',
  recoveryPassword = 'auth/recoveryPassword',
  newPassword = 'auth/recovery',
  addInstructionBot = 'settings_bot/add_instruction_bot',
  settingsBot = 'settings_bot',
  findApiKey = 'settings_bot/find_api_key',
  telegiv = 'telegiv',
  incorrectOperationBot = 'settings_bot/incorrect_operation_bot',
  createBot = 'settings_bot/create_bot',
  updateInformationBot = 'settings_bot/update_information_bot',
  editDescriptionBot = 'settings_bot/edit_description_bot',
  updateAvatar = 'settings_bot/update_avatar',
  greetingMessage = 'settings_bot/greeting_message',
  createDraw = 'create_draw',
  addMessageDraw = 'create_draw/add_message_draw',
  selectTariffPlan = 'settings_bot/select_tariff_plan',
  profile = 'profile',
  completedDrawResults = 'profile/draw_results',
  catalogCard = 'catalog_card',

  //ADMIN ROUTES PATH
  admin = '/admin',
  users = '/admin/users',
  editUser = '/admin/edit',
  promoCode = '/admin/promo',
  transfers = '/admin/transfers',
  adminTeam = '/admin/team',
  adminDraws = '/admin/draws',
  adminManagerClients = 'manager_clients',
  adminManagerTeam = 'manager_team',

  //MAIN APP ROUTES PATH
  main = '/',
  mainDraws = '/main-draws',
  mainFinishDraws = '/main-finish-draws',
  result = '/result',
  confirmEmail = '/auth/registration-confirmation',

  //SALES ROUTES PATH
  sales = 'sales/*',
  salesBaseUrl = 'sales',
  salesCookie = 'cookie',
  salesPrivacyPolicy = 'privacy-policy',
  salesLogin = 'auth/login',
  salesRegistration = 'auth/registration',
  salesRecoverPass = 'auth/recover_password',
  salesNewPassword = 'auth/manager/recovery',
  salesHome = 'home',
  salesBalance = 'balance',
  salesAllClients = 'all_clients',
  salesAllTeam = 'all_team',
  salesKnowledgeBase = 'info',
  salesSettings = 'settings',
  salesWithdrawals = 'withdrawals',

  //STATISTICS ROUTES PATH
  stat = '/stat',
  statLogin = '/stat/login',
}
