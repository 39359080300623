import Paper from '@mui/material/Paper';
import { useFormik } from 'formik';
import { Navigate } from 'react-router-dom';
import { PATH } from '../../../common/path/puth';
import s from './StatLogin.module.scss';
import { BlockTitle } from '../../sales/common/components/blockTitle/BlockTitle';

import { useState } from 'react';
import { StatHeader } from '../statHeader/StatHeader';
import { Button, Input } from '@mui/material';

export const StatLogin = () => {
  const [isValidPass, setIsValidPass] = useState(false);
  const [isInvalidLogin, setIsInvalidLogin] = useState(false);

  const formik = useFormik({
    initialValues: {
      pass: '',
    },
    onSubmit: values => {
      if (values.pass === '123456789') {
        sessionStorage.setItem('token', values.pass);
        setIsValidPass(true);
      } else setIsInvalidLogin(true);
    },
  });

  const handleChange = () => setIsInvalidLogin(false);

  if (isValidPass) {
    return <Navigate to={PATH.stat} />;
  }

  return (
    <div className={s.mainContainer}>
      <StatHeader />
      <Paper elevation={2} className={s.passContainer}>
        <BlockTitle title={'Пароль'} />
        <form onSubmit={formik.handleSubmit} onChange={handleChange} className={`${s.formContainer}`}>
          <Input
            className={isInvalidLogin ? `${s.errorBorder} ${s.passField}` : s.passField}
            disableUnderline
            fullWidth={true}
            {...formik.getFieldProps('pass')}
          />
          <Button className={s.enterButton} fullWidth type='submit'>
            Войти
          </Button>
        </form>
      </Paper>
    </div>
  );
};
