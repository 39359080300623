import React, { FC } from 'react';
import { useAppSelector } from '../../../../common/hooks/storeHooks';
import { getPlanCount } from '../../../../common/utils/ratePlanCount';
import { UsersTableLayout } from './UsersTableLayout';
import { UsersTableRow } from './UsersTableRow';
import { serverDateFormat } from '../../../sales/common/utils/serverDateFormat';
import { getCustomersSelector } from '../../adminSelectors';
import { SortVariantType } from '../../commonAdminTypes';

type PropsType = {
  sortVariant: SortVariantType;
  sortChangeHandler: () => void;
};
export const UsersTable: FC<PropsType> = ({ sortVariant, sortChangeHandler }) => {
  const customers = useAppSelector(getCustomersSelector);

  const mappedUsersTableRows = customers.map(({ id, rate_plan_id, draws_count, creation_timestamp, email_address }) => {
    const regDate = serverDateFormat(creation_timestamp);
    const ratePlan = getPlanCount(rate_plan_id);
    return (
      <UsersTableRow
        key={id}
        id={id}
        regDate={regDate}
        email={email_address}
        drawsCount={draws_count}
        ratePlan={ratePlan}
        ratePlanId={rate_plan_id}
      />
    );
  });

  return (
    <UsersTableLayout sortChangeHandler={sortChangeHandler} sortVariant={sortVariant}>
      {mappedUsersTableRows}
    </UsersTableLayout>
  );
};
