import React, { useEffect } from 'react';
import { BlockTitle } from '../../../common/components/blockTitle/BlockTitle';
import { ClientsTableLayout } from './clinetsTable/ClientsTableLayout';
import { ClientsTableRow } from './clinetsTable/ClientsTableRow';
import { SalesPagination } from '../../../common/components/pagination/Pagination';
import { useAppDispatch, useAppSelector } from '../../../../../common/hooks/storeHooks';
import { clientsPagesCountSelector, getClientsSelector, isFetchingSelector } from '../../../managerPublicSelectors';
import { publicManagerThunks } from '../../../publicManagerSlice';
import { PreLoader } from '../../../common/components/preLoader/PreLoader';
import { serverDateFormat } from '../../../common/utils/serverDateFormat';
import { useCustomSearchParams } from '../../../../../common/hooks/useCustomSearchParams';

type SalesAllClientsParams = 'page' | 'size';
export const AllClients = () => {
  const dispatch = useAppDispatch();
  const pagesCount = useAppSelector(clientsPagesCountSelector);
  const clients = useAppSelector(getClientsSelector);

  const isFetching = useAppSelector(isFetchingSelector);
  const [searchParams, setSearchParams] = useCustomSearchParams<SalesAllClientsParams>();

  const currentPage = searchParams.page || '1';
  const pageSize = searchParams.size || '10';

  useEffect(() => {
    dispatch(publicManagerThunks.getClients({ page: currentPage, size: pageSize }));
  }, [currentPage]);

  const onChangePage = (page: string) => {
    setSearchParams({ ...searchParams, page });
  };

  const clientsTableRows = clients.map(
    ({ id, count_subscribes, email_address, usr_tg, rate_plan, creation_timestamp }) => {
      const regDate = serverDateFormat(creation_timestamp);
      return (
        <ClientsTableRow
          key={id}
          subscribers={count_subscribes}
          email={email_address}
          telegram={usr_tg ?? ''}
          ratePlan={rate_plan}
          regDate={regDate}
        />
      );
    }
  );

  return (
    <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
      <BlockTitle title={'Клиенты'} />
      {isFetching ? (
        <PreLoader />
      ) : (
        <>
          <ClientsTableLayout>{clientsTableRows}</ClientsTableLayout>
          <SalesPagination page={currentPage} onChange={onChangePage} pagesCount={pagesCount} disabled={isFetching} />
        </>
      )}
    </div>
  );
};
