import { HeadCell } from './types';

export const headCells: readonly HeadCell[] = [
  {
    id: 'id',
    label: 'Номер шаблона',
  },
  {
    id: 'ignored',
    label: 'Динамят',
  },
  {
    id: 'blocked',
    label: 'Заблокировали',
  },
  {
    id: 'returned',
    label: 'Вернулись',
  },
  {
    id: 'reached',
    label: 'Дошли',
  },
];
