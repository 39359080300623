import React from 'react';
import s from './AdminButtonGroup.module.scss';
import { NavLink } from 'react-router-dom';
import { PATH } from '../../../common/path/puth';

export const AdminButtonGroup = () => {
  const getClassName = ({ isActive }: { isActive: boolean }) => (isActive ? s.activeHeaderLink : s.headerLink);

  return (
    <div className={s.buttonGroup}>
      <NavLink className={getClassName} to={PATH.adminDraws}>
        Розыгрыши
      </NavLink>
      <NavLink className={getClassName} to={PATH.transfers}>
        Переводы
      </NavLink>
      <NavLink className={getClassName} to={PATH.adminTeam}>
        Сейлзы
      </NavLink>
      <NavLink className={getClassName} to={PATH.promoCode}>
        Промокоды
      </NavLink>
      <NavLink className={getClassName} to={PATH.users}>
        Пользователи
      </NavLink>
    </div>
  );
};
