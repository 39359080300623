import React, { FC, PropsWithChildren } from 'react';
import s from './UsersTable.module.scss';
import { useWindowDimensions } from '../../../../common/utils/useWindowDimensions';
import { SortVariantType } from '../../commonAdminTypes';
import sortAsc from '../../../../assets/svg/sort_asc.svg';
import sortDesc from '../../../../assets/svg/sort_desc.svg';

type PropsType = {
  sortVariant: SortVariantType;
  sortChangeHandler: () => void;
};
export const UsersTableLayout: FC<PropsType & PropsWithChildren> = ({ children, sortChangeHandler, sortVariant }) => {
  const { width } = useWindowDimensions();
  const CHANGE_DATE_TITLE_WIDTH = 905;
  const CHANGE_SUBSCRIBERS_TITLE_WIDTH = 775;
  const isDateTitleChange = width <= CHANGE_DATE_TITLE_WIDTH;
  const isSubscribersTitleChange = width <= CHANGE_SUBSCRIBERS_TITLE_WIDTH;

  const sortIconVariant = sortVariant === 'asc' ? sortAsc : sortDesc;

  return (
    <div className={s.tableWrapper}>
      <table className={s.table}>
        <thead>
          <tr>
            <th className={s.dateCol}>{isDateTitleChange ? 'Дата' : 'Дата регистрации'}</th>
            <th className={s.idCol}>
              ID
              <img className={s.sort_icon} onClick={sortChangeHandler} src={sortIconVariant} alt={'sort'} />
            </th>
            <th className={s.emailCol}>Email</th>
            <th className={s.subscriptionCol}></th>
            <th className={s.subscribersCountCol}>{isSubscribersTitleChange ? 'Подписч' : 'Кол-во Подписчиков'}</th>
            <th className={s.editCol}></th>
          </tr>
        </thead>
        <tbody>{children}</tbody>
      </table>
    </div>
  );
};
