import React from 'react';
import { useWindowDimensions } from '../../../common/utils/useWindowDimensions';
import { HeaderBurger } from '../../sales/common/components/header/HeaderBurger';
import { InOutButton } from '../../../common/components/inOutButton/InOutButton';
import { useNavigate } from 'react-router-dom';
import { PATH } from '../../../common/path/puth';
import { AdminButtonGroup } from './AdminButtonGroup';
import { useAppDispatch } from '../../../common/hooks/storeHooks';
import { adminAuthActions } from '../adminAuthSlice';

export const AdminHeaderMenu = () => {
  const navigate = useNavigate();
  const { width } = useWindowDimensions();
  const dispatch = useAppDispatch();

  const SHOW_BURGER_WIDTH = 900;
  const isBurgerShow = width <= SHOW_BURGER_WIDTH;

  const handleLogout = () => {
    dispatch(adminAuthActions.removeTokenAfterLogout());
    sessionStorage.removeItem('token');
    navigate(PATH.admin);
  };

  const menuItems = [
    { buttonTitle: 'Переводы', buttonAction: () => navigate(PATH.transfers) },
    { buttonTitle: 'Команда', buttonAction: () => navigate(PATH.adminTeam) },
    { buttonTitle: 'Промокоды', buttonAction: () => navigate(PATH.promoCode) },
    { buttonTitle: 'Пользователи', buttonAction: () => navigate(PATH.users) },
    { buttonTitle: 'Розыгрыши', buttonAction: () => navigate(PATH.adminDraws) },
  ];

  return (
    <div style={{ display: 'flex', gap: '15px' }}>
      {isBurgerShow ? (
        <HeaderBurger handleLogout={handleLogout} menuItems={menuItems} />
      ) : (
        <>
          <AdminButtonGroup />
          <InOutButton callback={handleLogout} btnTitle={'Выход'} />
        </>
      )}
    </div>
  );
};
