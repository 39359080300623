import { Navigate } from 'react-router-dom';
import { PATH } from '../../common/path/puth';
import { StatHeader } from './statHeader/StatHeader';
import s from './Stat.module.scss';
import { StatMain } from './statMainPage/StatMain';

export const Stat = () => {
  const token = sessionStorage.getItem('token');

  if (token === '123456789') {
    return (
      <div className={s.container}>
        <StatHeader />
        <StatMain />
      </div>
    );
  }

  return <Navigate to={PATH.statLogin} />;
};
